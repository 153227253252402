import {
  Fragment,
  useContext,
} from 'react';

import Web3Context from '../../../store/web3-context';

import Hallway from './Hallway/Hallway';
import Entrance from './Entrance/Entrance';

const connectWallet = async (pvdr, ctx) => {
  try {
    await pvdr.connect();
  } catch (err) {
    ctx.onAddLog(`[error] connection error: ${JSON.stringify(err)}`);
  }
};

const Hideout = () => {

  const ctx = useContext(Web3Context);

  return <Fragment>
    {ctx.provider && ctx.publicKey ? <Hallway /> : <Entrance connectWallet={connectWallet} />}
  </Fragment>;
}

export default Hideout;
