import styles from './Content.module.css';

import {
  Routes,
  Route,
} from 'react-router-dom';

import Home from '../../../Content/Home/Home';
import Hideout from '../../../Utilities/Hideout/Hideout';
import Whitepaper from '../../../Content/Whitepaper/Whitepaper';
import Acknowledgements from '../../../Content/Acknowledgements/Acknowledgements';
import Hallway from '../../../Utilities/Hideout/Hallway/Hallway';
import JuiceRoom from '../../../Utilities/Hideout/GroundFloor/JuiceRoom/JuiceRoom';
import VCardRoom from '../../../Utilities/Hideout/GroundFloor/VCardRoom/VCardRoom';
import GiveawaysRoom from '../../../Utilities/Hideout/GroundFloor/GiveawaysRoom/GiveawaysRoom';
import BountyRoom from '../../../Utilities/Hideout/GroundFloor/BountyRoom/BountyRoom';
import Elevator from '../../../Utilities/Hideout/Elevator/Elevator';

const Content = props => {
  return (
    <Routes>

      <Route
        path="/"
        element={<Home 
          styles={styles}
        />}
      />
      <Route
        path="hideout"
        element={<Hideout 
          styles={styles}
        />}
      />
      <Route
        path="whitepaper"
        element={<Whitepaper 
          styles={styles}
        />}
      />
      <Route
        path="acknowledgements"
        element={<Acknowledgements 
          styles={styles}
        />}
      />
      <Route
        path="hallway"
        element={<Hallway 
          styles={styles}
        />}
      />
      <Route
        path="juice_room"
        element={<JuiceRoom 
          styles={styles}
        />}
      />
      <Route
        path="vcard_room"
        element={<VCardRoom 
          styles={styles}
        />}
      />
      <Route
        path="giveaways_room"
        element={<GiveawaysRoom 
          styles={styles}
        />}
      />
      <Route
        path="bounty_room"
        element={<BountyRoom 
          styles={styles}
        />}
      />
      <Route
        path="elevator"
        element={<Elevator 
          styles={styles}
        />}
      />

    </Routes>
  );
};

export default Content;