import { Fragment } from "react";
import Article from "../../UI/Article/Article";
import Section from "../../UI/Section/Section";

// import petshop from '../../../assets/img/petshop.jpg';

const Home = props => {
  return (
    <Section className={props.styles.MainContent}>

      {/* <img
        className={styles.heroBanner}
        src={petshop}
        alt={`Pet Shop`}
       /> */}

      <Article
        className={props.styles.pageDescription ? props.styles.pageDescription : ''}
        heading='Tell her she looks thin.'
        content={<Fragment>
          <p>
            Oh, how awful. Did he at least die painlessly? …To shreds, you say. Well, how is his wife holding up? …To shreds, you say. I feel like I was mauled by Jesus. We can't compete with Mom! Her company is big and evil! Ours is small and neutral!
          </p>
  
          <p>
            These old Doomsday Devices are dangerously unstable. I'll rest easier not knowing where they are. You wouldn't. Ask anyway! It's just like the story of the grasshopper and the octopus. All year long, the grasshopper kept burying acorns for winter, while the octopus mooched off his girlfriend and watched TV. But then the winter came, and the grasshopper died, and the octopus ate all his acorns. Also he got a race car. Is any of this getting through to you?
          </p>
  
          <p>
            Bender, this is Fry's decision… and he made it wrong. So it's time for us to interfere in his life. So, how 'bout them Knicks? Hey, whatcha watching? Humans dating robots is sick. You people wonder why I'm still single? It's 'cause all the fine robot sisters are dating humans!
            Dr. Zoidberg, that doesn't make sense. But, okay! Daddy Bender, we're hungry. Too much work. Let's burn it and say we dumped it in the sewer. Come, Comrade Bender! We must take to the streets! Tell her you just want to talk. It has nothing to do with mating.
            THE BIG BRAIN AM WINNING AGAIN! I AM THE GREETEST! NOW I AM LEAVING EARTH, FOR NO RAISEN! Fry, you can't just sit here in the dark listening to classical music. Maybe I love you so much I love you no matter who you are pretending to be.
            And why did 'I' have to take a cab? And then the battle's not so bad? It doesn't look so shiny to me. Ah, yes! John Quincy Adding Machine. He struck a chord with the voters when he pledged not to go on a killing spree.
          </p>
        </Fragment>}
      />

      <Article
        className={props.styles.pageDescription ? props.styles.pageDescription : ''}
        heading={'It doesn\'t look so shiny to me.'}
        content={<Fragment>
          <p>
          Stop! Don't shoot fire stick in space canoe! Cause explosive decompression! Yes, I saw. You were doing well, until everyone died. Eeeee! Now say "nuclear wessels"! You've killed me! Oh, you've killed me!
          </p>
  
          <p>
          That could be 'my' beautiful soul sitting naked on a couch. If I could just learn to play this stupid thing. You wouldn't. Ask anyway! Hey, you add a one and two zeros to that or we walk! You know, I was God once.
          </p>
  
          <p>
          No! The kind with looting and maybe starting a few fires! No! The kind with looting and maybe starting a few fires! I found what I need. And it's not friends, it's things. Too much work. Let's burn it and say we dumped it in the sewer.
          </p>
        </Fragment>}
      />

    </Section>
  );
};

export default Home;