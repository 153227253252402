import { useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { view } from '@overlapmedia/imagemapper';

import Web3Context from '../../../../store/web3-context';

import elevatorImage from './Elevator.svg';
import elevatorJson from './Elevator.json';

function Elevator() {

  const ctx = useContext(Web3Context);
  
  const navigate = useNavigate();

  useEffect(() => {
    if(!ctx.provider || !ctx.publicKey) {
      navigate('/hideout');
    }
  }, []);

  const elevator = useRef(null);

  useEffect(() => {

    const h = view(elevator.current, {
      width: '100%',
      viewClickHandler: (e, id) => {
        switch(id) {
          case 'polygon_1':
            navigate('/hallway');
            break;
          default:
            console.log(e.target.id);
        }
      },
    })
    h.loadImage(elevatorImage, 800, 400);
    h.import(
      JSON.stringify(elevatorJson),
    );
    h.on('hover', (e) => console.log('hover event', e));

  },[navigate]);

  return <svg
    ref={elevator}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    viewBox="0, 0, 800, 400"
    preserveAspectRatio="xMinYMin"
  />;
}

export default Elevator;
