import styles from './Wrapper.module.css';

import Main from '../../UI/Main/Main';
import Content from './Content/Content';
import Console from '../../debugging/Console/Console';

const Wrapper = props => {
  return (
    <Main className={styles.Wrapper}>

      <Console />

      <Content></Content>


    </Main>
  );
};

export default Wrapper;