import styles from './PageFooter.module.css';

import Footer from '../../UI/Footer/Footer';
import Copyright from './Copyright/Copyright';
import Social from '../../Utilities/Social/Social';

const PageFooter = props => {
  return (
    <Footer className={styles.pageFooter}>

      <Social className={styles.socialLinks}></Social>

      <Copyright className={styles.copyright}></Copyright>

    </Footer>
  );
};

export default PageFooter;