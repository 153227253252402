import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Web3Context from '../../../../../store/web3-context';

function BountyRoom() {

  const ctx = useContext(Web3Context);
  
  const navigate = useNavigate();

  useEffect(() => {
    if(!ctx.provider || !ctx.publicKey) {
      navigate('/hideout');
    }
  }, []);

  return <p>Bounty Room Goes Here</p>;
}

export default BountyRoom;
