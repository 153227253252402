import {
  useEffect,
  useCallback,
  Fragment,
  useContext
} from 'react';
import { useSearchParams } from 'react-router-dom';
import {encode as base64_encode} from 'base-64';
import base58 from 'bs58';

import Button from '../../UI/Button/Button';
import Article from '../../UI/Article/Article';
import Web3Context from '../../../store/web3-context';

import styles from './WalletConnect.module.css';

export const getProvider = () => {
  if ('solana' in window) {
    const anyWindow = window;
    const provider = anyWindow.solana;
    
    if (provider.isPhantom) {
      return provider;
    }
  }
  return undefined;
};

export const connectWallet = async (pvdr, ctx) => {
  try {
    await pvdr.connect();
  } catch (err) {
    ctx.onAddLog(`[error] connection error: ${JSON.stringify(err)}`);
  }
};

const WalletConnect = props => {

  const ctx = useContext(Web3Context);

  let provider = ctx.provider;
  if(!provider) {
    provider = getProvider();
  }
  if (provider === undefined) ctx.onAddLog('no provider');

  const [searchParams,] = useSearchParams();

  let checked=false;
  const checkGetArgs=useCallback(() =>{
    if(!checked){
      checked=true;
      ctx.onAddLog("args:"+searchParams)
      if(searchParams.has("state") && searchParams.has("code")){

        let state=searchParams.get("state")
        let code=searchParams.get("code")

        fetch("https://dao.solanap.business/auth"+"?state="+state+"&code="+code,                              {
          method: "GET"
        })
        .then(response => {
            ctx.onAddLog(response.status.toString());
            return response.text();
        })
        .then(data => {
          ctx.onAddLog(data);
        });
      }
    }
  },[checked])


  useEffect(() => {
    checkGetArgs();
    if (!provider) return;
    // try to eagerly connect
    provider.connect({ onlyIfTrusted: true }).catch((err) => {
      // fail silently
    });
    provider.on('connect', (publicKey) => {
      ctx.onConnectWallet(provider);
      ctx.onUpdatePublicKey(publicKey);
      ctx.onConnected(true);

      ctx.onAddLog('[connect] ' + publicKey?.toBase58());
    });
    provider.on('disconnect', () => {
      ctx.onUpdatePublicKey(null);
      ctx.onConnected(false);

      ctx.onAddLog('[disconnect] 👋');

      ctx.onConnectWallet(provider);
    });
    provider.on('accountChanged', (publicKey) => {
      ctx.onUpdatePublicKey(publicKey);


      if (publicKey) {

        ctx.onAddLog('[accountChanged] Switched account to ' + publicKey?.toBase58());
      } else {
 
        ctx.onAddLog('[accountChanged] Switched unknown account');
        // In this case, dapps could not to anything, or,
        // Only re-connecting to the new account if it is trusted
        // provider.connect({ onlyIfTrusted: true }).catch((err) => {
        //   // fail silently
        // });
        // Or, always trying to reconnect
        provider
          .connect()
          .then(() => ctx.onAddLog('[accountChanged] Reconnected successfully'))
          .catch((err) => {
            console.error(err)
            ctx.onAddLog('[accountChanged] Failed to re-connect: ' + err.message);
          });
      }

      ctx.onConnectWallet(provider);
    });
    return () => {
      provider.disconnect();
    };
  },[
    provider,
    checkGetArgs
  ]);
  // }, [provider, addLog]);

  if (!provider) {
    return <h2>Could not connect to wallet</h2>;
  }

  const signMessage = async () => {
    let res=null
    let word='thugnerdz2';
    const data = new TextEncoder().encode(word);
    try {
      res = await provider.signMessage(data);
      //may as well use md5 for avoiding large messages twice
    } catch (err) {
      console.warn(err);
      ctx.onAddLog('[error] Message not signed: ' + JSON.parse(JSON.stringify(err)).message);
    }
    if (res != null)
    {
      let parsed = JSON.parse(JSON.stringify(res)).signature;
      let extra={
        'user': ctx.publicKey.toString(),
        'signature': base58.encode(parsed.data),
        'data': base64_encode(word)
      }
      ctx.onAddLog(JSON.stringify(extra))
      let url='https://discord.com/oauth2/authorize?client_id=957425713437360218&redirect_uri=https%3A%2F%2Fdao.solanap.business%2Fauth&response_type=code&scope=identify%20guilds&state=';
      url +=base64_encode(JSON.stringify(extra));
      
      window.location.href=url
    }
  };

  const disconnectWallet = async () => {
    try {
      await provider.disconnect();
    } catch (err) {
      console.warn(err);
      ctx.onAddLog(`[error] disconnect: ${JSON.stringify(err)}`);
    }
  };

  const ellipsisString = (maxlen, str) => {
    if (str.length > maxlen) {
      return str.substr(0, ((maxlen / 2) - 1)) + '...' + str.substr(str.length - ((maxlen / 2) - 2), str.length);
    }
    return str;
  }

  const WalletConnected = () => {
    
    let connectedWallet = ellipsisString(12, ctx.publicKey.toBase58());
    return(
      <Article
        heading={connectedWallet}
        headingSize='5'
        className={styles.walletConnect}
      >
        <Button onClick={signMessage}>
          Link Discord
        </Button>
        <Button onClick={disconnectWallet}>
          Disconnect
        </Button>
      </Article>
    );
  }
  
  return (
    <Fragment>
    {/* {provider && publicKey ? <WalletConnected /> : <WalletDisconnected />} */}
      {provider && ctx.publicKey ? <WalletConnected /> : ''}
    </Fragment>
  );
}

export default WalletConnect;
