import {
  useContext,
  useState
} from "react";
import CodeEditor from '@uiw/react-textarea-code-editor';

import styles from './Console.module.css';
import Web3Context from "../../../store/web3-context";

const Console = (props) => {
  
  const ctx = useContext(Web3Context);

  const [code, setCode] = useState(
    `function add(a, b) {\n  return a + b;\n}`
  );

  return (
    <div className={styles.logs}>
      <div>
        <CodeEditor
          value={code}
          language="yaml"
          placeholder="kube"
          onChange={(evn) => setCode(evn.target.value)}
          padding={15}
        />
      </div>
      <CodeEditor
        readOnly
        value={ctx.logs.join("\n").toString()}
        language="verilog"
        placeholder="kube"
        padding={15}
      />
    </div>
  );
}

export default Console;