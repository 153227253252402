import { useContext, useEffect, useRef } from "react";
import { view } from '@overlapmedia/imagemapper';

import Web3Context from '../../../../store/web3-context';

import entranceImage from '../Hideout.svg';
import entranceJson from '../Hideout.json';

const getProvider = () => {
  if ('solana' in window) {
    const anyWindow = window;
    const provider = anyWindow.solana;
    
    if (provider.isPhantom) {
      return provider;
    }
  }
  return undefined;
};

const connectWallet = async (pvdr, ctx) => {
  try {
    await pvdr.connect();
  } catch (err) {
    ctx.onAddLog(`[error] connection error: ${JSON.stringify(err)}`);
  }
};

const Entrance = props => {

  const ctx = useContext(Web3Context);

  useEffect(() => {

    const h = view(hideout.current, {
      width: '100%',
      viewClickHandler: (e, id) => {
        switch(id) {
          case 'polygon_1':

            const provider = getProvider();
            if (provider === undefined) ctx.onAddLog('no provider');

            connectWallet(provider, ctx);
            break;
          default:
            console.log('Unknown area');
        }
      },
    })
    h.loadImage(entranceImage, 800, 400);
    h.import(
      JSON.stringify(entranceJson),
    );
    h.on('hover', (e) => console.log('hover event', e));

  },[]);

  const hideout = useRef(null);

  return <svg
      ref={hideout}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      viewBox="0, 0, 800, 400"
      preserveAspectRatio="xMinYMin"
    />
};

export default Entrance;