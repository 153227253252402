import styles from './PageHeader.module.css';

import Header from '../../UI/Header/Header';
import PageNavigation from './PageNavigation/PageNavigation';
import Social from '../../Utilities/Social/Social';
import Brand from './Brand/Brand';
import Aside from '../../UI/Aside/Aside';
import WalletConnect from '../../Utilities/WalletConnect/WalletConnect';

const PageHeader = props => {

  return (
    <Header className={styles.pageHeader}>

      <Brand />

      <Aside className={styles.navSection}>
        <PageNavigation />

        <WalletConnect />

        <Social className={styles.socialLinks} />
      </Aside>
    </Header>
  );
};

export default PageHeader;