import styles from './Brand.module.css';

import logo from '../../../../logo.svg';
import { Link } from "react-router-dom";

const Brand = props => {
  return (
    <Link to="/">
      <img src={logo} className={styles.logo} alt="Nerd DAO" />
    </Link>
  );
};

export default Brand;