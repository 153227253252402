// import styles from './Whitepaper.module.css';

import Section from "../../UI/Section/Section";

const Whitepaper = props => {
  return (
    <Section className={`${props.styles.MainContent}`}>

      <h2>Whitepaper</h2>
      <p>Embed gitbook PDF here (iframe?)</p>

    </Section>
  );
};

export default Whitepaper;