import { useState, useCallback } from 'react';

import { BrowserRouter } from 'react-router-dom';

import PageHeader from './components/Layout/PageHeader/PageHeader';
import Wrapper from './components/Layout/Wrapper/Wrapper';
import PageFooter from './components/Layout/PageFooter/PageFooter';

import Web3Context from './store/web3-context';

import styles from './App.module.css';

const App = () => {

  const [logs, setLogs] = useState([]);

  const [provider, setProvider] = useState(null);

  const [publicKey, setPublicKey] = useState(null);

  const [, setConnected] = useState(false);

  const addLog = useCallback(
    (log) => setLogs((logs) => [...logs, "> " + log]),
    []
  );

  const updateLog = str => {
    addLog(str);
  }

  const updateProvider = provider => {
    setProvider(provider);
  }

  const updatePublicKey = publicKey => {
    setPublicKey(publicKey);
  }

  const connectionSuccess = success => {
    setConnected(success);
  }

  return (

    <BrowserRouter>

        <Web3Context.Provider value={{
          logs: logs,
          onAddLog: updateLog,
          provider: provider,
          onConnectWallet: updateProvider,
          publicKey: publicKey,
          onUpdatePublicKey: updatePublicKey,
          onConnected: connectionSuccess,
        }}>

          <PageHeader />

          <Wrapper />

          <PageFooter />

        </Web3Context.Provider>


    </BrowserRouter>
  );
}

export default App;
