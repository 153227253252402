import { useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { view } from '@overlapmedia/imagemapper';

import Web3Context from '../../../../store/web3-context';

import hallwayImage from './Hallway.png';
import hallwayJson from './Hallway.json';

function Hallway() {

  const hallway = useRef(null);

  const ctx = useContext(Web3Context);
  
  const navigate = useNavigate();

  useEffect(() => {
    if(!ctx.provider || !ctx.publicKey) {
      navigate('/hideout');
    }
  }, []);

  useEffect(() => {

    const h = view(hallway.current, {
      width: '100%',
      viewClickHandler: (e, id) => {
        switch(id) {
          case 'juiceroom_door':
            navigate('/juice_room');
            break;
          case 'vcardroom_door':
            navigate('/vcard_room');
            break;
          case 'giveawaysroom_door':
            navigate('/giveaways_room');
            break;
          case 'bountyroom_door':
            navigate('/bounty_room');
            break;
          case 'elevator_door':
            navigate('/elevator');
            break;
          default:
            console.log(e.target.id);
        }
      },
    })
    h.loadImage(hallwayImage, 800, 400);
    h.import(
      JSON.stringify(hallwayJson),
    );
    h.on('hover', (e) => console.log('hover event', e));

  },[navigate]);

  return <svg
    ref={hallway}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    viewBox="0, 0, 800, 400"
    preserveAspectRatio="xMinYMin"
  />;
}

export default Hallway;
