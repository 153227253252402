import styles from './Controls.module.css';

import Aside from '../../../../../UI/Aside/Aside';
import Button from '../../../../../UI/Button/Button';

const Controls = props => {

  const stakeJuice = () => {
    console.log('Staking juice!');
  }

  const claimJuice = () => {
    console.log('Claiming juice!');
  }

  const burnJuice = () => {
    console.log('Burning juice!');
  }

  return (
    <Aside className={styles.juiceRoomControls}>
      <Button onClick={stakeJuice}>Stake Juice</Button>
      <Button onClick={claimJuice}>Claim Juice</Button>
      <Button onClick={burnJuice}>Burn Juice</Button>
    </Aside>
  );
};

export default Controls;