import { useContext, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './JuiceRoom.module.css';
import juiceRoomImg from './JuiceRoomBgImg.png';

import Section from "../../../../UI/Section/Section";
import Article from '../../../../UI/Article/Article';
import Controls from './Controls/Controls';

import Web3Context from '../../../../../store/web3-context';

const JuiceRoom = props => {

  const ctx = useContext(Web3Context);
  
  const navigate = useNavigate();

  useEffect(() => {
    if(!ctx.provider || !ctx.publicKey) {
      navigate('/hideout');
    }
  }, []);

  return (
    <Section className={`${props.styles.MainContent} ${styles.JuiceRoomContainer}`}>
      <div className={styles.juiceRoomImgContainer}>
        <img
          src={juiceRoomImg}
          alt='
            You are in a dimly-lit room. There is a computer terminal to your left with a display which shows some of the burned nerds.
            Canisters containing liquids of various colours are mounted on the wall in front of you. Thick, black tubes appear to
            feed into the large canister of $JUICE which is mounted in the centre of the room, off to the right. In the back-right
            corner of the room, there is a large stack of 20 servers with wiring which travels up through the ceiling. Standing in front
            of you, with a casual look on his face and a half-smoked joint protruding from his lips, is one of the NerdDAOs top scientists.
          '
        />

        <div className={styles.scientistContainer}>
          <div className={styles.scientist}></div>
  
            <Article
              className={styles.speechBubble}
              heading='Welcome to the juice room'
              content={<Fragment>
                <p>Which operation would you like to perform today?</p>
              </Fragment>}
            >
              <Controls></Controls>
  
              <div className={styles.speechBubbleWick}>
                <div></div>
              </div>
            </Article>
        </div>
      </div>
    </Section>
  );
};

export default JuiceRoom;