// import styles from './Acknowledgements.module.css';

import Section from "../../UI/Section/Section";
import ListItem from "../../UI/List/ListItem";
import UnorderedList from "../../UI/List/UnorderedList";

const Acknowledgements = props => {
  return (
    <Section className={`${props.styles.MainContent}`}>

      <UnorderedList>
        <ListItem>
          Founder:
          <br />
          Scrumpy
        </ListItem>
          <br />
        <ListItem>
          Artwork and web concept:
          <br />
          Sinful Sloth
        </ListItem>
          <br />
        <ListItem>
          Website design:
          <br />
          Gattytto
          <br />
          Muckee
        </ListItem>
      </UnorderedList>

    </Section>
  );
};

export default Acknowledgements;